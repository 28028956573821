* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body,
html {
	background: black;
	font-family: Verdana, Geneva, Tahoma, sans-serif
}

select {
	width: 100%;
	height: 100%;
	border: none;
	background: white;
	font-size: 1.5em;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	border: none;
	border-radius: 7px;
}